import * as React from 'react';

function SvgTackleBox(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="currentColor" fill="none" fillRule="evenodd">
        <path d="M16.445 7.803l-.493-4.063H2.37l-.493 4.063H1v3.233h.877l.337 3.341c.081.921.854 1.627 1.779 1.623h10.336a1.779 1.779 0 001.78-1.623l.336-3.341h.877V7.803h-.877zM16.445 11.036H1.877M5.904 3.728V1h6.514v2.728M7.659 7.647h3.004" />
      </g>
    </svg>
  );
}

export default SvgTackleBox;
