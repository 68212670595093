import React from 'react';

const SvgTechniques = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    width="1.18em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 22"
    {...props}
  >
    <path
      d="M6.367 20.55a5.367 5.367 0 1 0 0-10.733 5.367 5.367 0 0 0 0 10.733ZM20.517 17.183 24.333 21M4.333 1l14.284 14.283M20.95 20.35l2.733-2.733M19.683 17.65a1.35 1.35 0 1 0 0-2.7 1.35 1.35 0 0 0 0 2.7ZM6.267 3.067v6.566M6.333 12.517V17.5M8.817 15.017H3.85"
      stroke="currentColor"
    />
  </svg>
);

export default SvgTechniques;
