import { Product } from '@omniafishing/core';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { customAttributesToAttributionQueryParams } from '../../lib/line_item_attribution';
import { OmniaUrls } from '../../lib/urls';
import { ProductClickParams, WebAnalytics } from '../../lib/web_analytics';
import { LineItemCustomAttribute } from '../../redux/cart';

export const LinkToProductDetailPage = (props: {
  product: Product;
  className?: string;
  currentColor?: boolean;
  children: ReactNode;
  onClick?: () => void;
  position: number;
  lineItemAttribution?: LineItemCustomAttribute[];
  skeleton?: boolean;
  list_uuid: string;
}) => {
  const {
    product,
    className,
    onClick,
    children,
    position,
    lineItemAttribution,
    skeleton,
    list_uuid,
  } = props;
  const { shopify_product_handle, shopify_options } = product;

  const productUrlSearch = `?${customAttributesToAttributionQueryParams(lineItemAttribution)}`;

  const sharedProps = {
    className,
    style: { color: 'currentColor' },
  };

  if (skeleton) {
    return <span {...sharedProps}>{children}</span>;
  }

  const productClickParams: ProductClickParams = {
    productOrProductFamily: product,
    show_modal: false,
    position,
  };

  if (list_uuid) {
    productClickParams.list_uuid = list_uuid;
  }

  return (
    <Link
      {...sharedProps}
      to={OmniaUrls.product(shopify_product_handle, shopify_options) + productUrlSearch}
      onClick={() => {
        onClick?.();
        WebAnalytics.productClick(productClickParams);
      }}
    >
      {children}
    </Link>
  );
};
